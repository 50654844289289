<template lang="html">
<div class="forbidden-container">
    404
</div>
</template>

<script>
export default {
    name: 'Forbidden',
}
</script>

<style lang="less" scoped>
@import "~base.less";

.forbidden-container {
    padding-top: 200px;
    font-size: 40px;
    text-align: center;
}
</style>
